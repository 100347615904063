var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Information de l'agence")])]},proxy:true}])},[_c('div',{staticClass:"text-right mb-4"},[_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":'Date de création: ' +
                  new Date(_vm.agence.created_at).toLocaleString(),"column":3}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.agence.nom_agence)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Ville"}},[_vm._v(" "+_vm._s(_vm.ville)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Quartier"}},[_vm._v(" "+_vm._s(_vm.quartier)+" ")])],1)],1)])],1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: '20px', paddingBottom: '16px' }}},[[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Affecter un carnet")])],_c('a-form',{staticClass:"affect-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formCarnetAffect,"hideRequiredMark":true},on:{"submit":_vm.agentCarnetSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24,"md":16}},[_c('a-form-item',{attrs:{"label":"Selectionnez le carnet"}},[_c('a-select',{attrs:{"placeholder":"Selectionnez le carnet","mode":"multiple","option-label-prop":"label","filter-option":false,"not-found-content":_vm.fetching ? undefined : null},on:{"search":_vm.fetchUser,"change":_vm.handleChange}},_vm._l((_vm.carnets),function(collecteur,index){return _c('a-select-option',{key:index,attrs:{"value":collecteur.id,"label":collecteur.libelle}},[_vm._v(" "+_vm._s(collecteur.libelle)+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("("+_vm._s(collecteur.tarif)+" Fcfa)")])])}),1)],1)],1),_c('a-col',{attrs:{"span":24,"md":8}},[_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'code_secret',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Code secret incorrect!',
                        } ],
                    } ]),expression:"[\n                    'code_secret',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Code secret incorrect!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"type":"number","placeholder":"Code secret"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":24}},[_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Affecter carnet ")])],1)])],1)],1)],2)],1),_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Liste des carnets affectés")]),_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"Recherche ici"},on:{"change":_vm.onSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns_carnet,"data-source":_vm.data_carnet},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('a-popconfirm',{attrs:{"title":"Sûre de retirer?"},on:{"confirm":function () { return _vm.removeCarnet(record.id); }}},[_c('a-button',{attrs:{"type":"danger","size":"small"}},[_vm._v("Retirer")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }